.container-landing {
  padding: 0 20rem;
}

.section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - 60px);
  gap: 25px;
}

.section-m {
  height: calc(100vh * 0.65);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.frame-section-txt {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 75rem;
  width: 75rem;
  border-radius: 20px;
  padding: 7.5rem 10rem;
  background-color: rgb(248, 249, 250);
}

.frame-section-txt h1 {
  font-size: 48px;
  font-weight: 900;
}

.frame-section-txt p {
  margin-top: 3.6rem;
  flex: 1;
  font-size: 18px;
}

.frame-section-img {
  width: 75rem;
  height: 75rem;
  border-radius: 20px;
}

.section img {
  height: 100%;
  object-fit: cover;
  border-radius: 20px; /* Ajusta el radio como quieras */
}

.background-image-container {
  position: relative;
  width: 100%;
  height: 50rem;
  border-radius: 20px;
  overflow: hidden;
}

.background-image-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 1;
}

.background-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.content-overlay {
  position: relative;
  z-index: 10;
  padding: 4rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  padding: 10rem 30rem;
}

.content-overlay h2 {
  font-size: 42px;
  font-weight: 900;
  text-align: center;
}

.content-overlay p {
  margin-top: 12px;
  font-size: 24px;
  font-weight: 100;
  text-align: center;
}

.content-overlay h2,
.content-overlay p {
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.7);
}

.buttons-container {
  margin-top: 5rem;
  display: flex;
  gap: 1.5rem;
}

.problems {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  justify-content: space-around;
  align-items: center;
  gap: 2.4rem;
}

.problem {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 2.4rem;
}

.problem-img {
  flex: 0.8;
  background-color: white;
  border-radius: 20px;
}

.problem-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* o 'contain' si prefieres que no se recorte */
  border-radius: 20px;
}

.problem-txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background-color: rgb(248, 249, 250);
  border-radius: 20px;
  padding: 2.4rem 4.8rem;
}

.problem span {
  font-size: 24px;
  font-weight: 100;
  text-align: right;
}

.footer {
  margin-top: 12rem;
  display: flex;
  background-color: rgb(248, 249, 250);
  padding: 4.2rem 20rem;
}

.footer-logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 120px;
}

.footer-logo img {
  width: 120px;
  height: 60px;
}

.footer-logo span {
  margin-top: 0.8rem;
  font-weight: bold;
}

.footer-col {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-col span {
  font-weight: bold;
  text-transform: uppercase;
}

.footer-col li {
  list-style: none;
  padding-left: 0;
  text-align: right;
}

.faq-section {
  margin: 0 auto;
  margin-top: 12rem;
  background-color: rgb(248, 249, 250);
  border-radius: 20px;
  padding: 4.8rem 9.6rem;
  display: flex;
  gap: 6rem;
  min-height: 50rem;
}

.faq-container {
  flex: 1;
}

.faq-container p {
  margin-top: 2.4rem;
}

.faq-container button {
  margin-top: 6rem;
}

.faq {
  border-bottom: 1px solid #ccc;
  padding: 1rem 0;
}

.faq input {
  display: none;
}

.faq label {
  display: block;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  padding-right: 2rem;
}

.faq label::after {
  content: '+';
  position: absolute;
  right: 0;
  transition: transform 0.3s;
}

.faq input:checked + label::after {
  content: '−';
  transform: rotate(180deg);
}

.faq .answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
  color: #555;
  padding-left: 1rem;
}

.faq input:checked ~ .answer {
  max-height: 200px; /* ajusta según necesidad */
  margin-top: 0.5rem;
}
