.header-logo-container {
  height: 35px;

  & > img {
    object-fit: contain;
    height: 100%;
    width: auto; /* El ancho se ajusta automáticamente según la proporción natural */
  }
}

.header-brand {
  padding: 0.5rem;
  font-size: 2.2rem;
  font-family: $main-font-family;
  color: #000000;
  text-decoration: none;
  display: flex;
  align-items: center;

  & p {
    margin: 0 !important;
  }

  &__bim {
    font-weight: 700;
  }
}
